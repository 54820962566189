import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import styles from './landinghero.module.scss'

const Form = () => {
  const { useState } = React

  const [fullName, setFullName] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [contactNumber, setContactNumber] = useState('')

  const isValid = () => !(fullName && (emailAddress || contactNumber));

  return (
    <form
      name="landing-contact-us-form"
      method="POST"
      action="/thank-you"
      data-netlify="true"
      netlify-honeypot="nofill"
      className={ styles.form }
    >
      <p className={ styles.form__textStyles }>
        Been injured? Not your fault? We can help! Get your free initial expert advice today!
      </p>
      <div>
        <label htmlFor="fullName" className={ styles.form__textStyles }>
          Your full name<sup style={{ color: 'red' }}>*</sup>
        </label>
        <input
          id="fullName"
          name="Full Name"
          type="text"
          placeholder="Joe | Jane Smith"
          className={ styles.form__inputStyles }
          onChange={e => setFullName(e.target.value)}
        >
        </input>
      </div>
      <p className={ styles.form__textStyles }>
        Only one of the following is required:
      </p>
      <div>
        <label htmlFor="emailAddress" className={ styles.form__textStyles }>
          Your email address:
        </label>
        <input
          id="emailAddress"
          name="Email Address"
          type="email"
          placeholder="youremail@email.com"
          className={ styles.form__inputStyles }
          onChange={e => setEmailAddress(e.target.value)}
        >
        </input>
      </div>
      <div style={{ margin: '3rem 0 0 0' }}>
        <label htmlFor="contactNumber" className={ styles.form__textStyles }>
          Your contact number:
        </label>
        <input
          id="contactNumber"
          name="Contact Number"
          type="tel"
          placeholder="0400 000 000"
          className={ styles.form__inputStyles }
          onChange={e => setContactNumber(e.target.value)}
        >
        </input>
      </div>
      <input
        type="submit"
        value="Review my case &rsaquo;"
        className={ styles.form__buttonStyle }
        disabled={isValid()}
      ></input>
      <p className={ styles.form__textStyles_center }>
        Or call <a href="tel:1300 206 952">1300 206 952</a> (open 24/7)
      </p>
      <input
        type="hidden"
        name="form-name"
        value="landing-contact-us-form"
      />
      <input
        type="text"
        placeholder="Don't fill this out if you're a human!"
        name="nofill"
        style={{
          position: 'absolute',
          top: '-9000px',
          left: '-9000px',
        }}
      />
    </form>
  )
}

const Hero = ({ leadLine, heroLine, heroPoints, data }) => {
  return (
    <div className={ styles.hero }>
      <div className={ styles.hero__offset }>
        <div className={ styles.relative_position }>
          <div className={ styles.flex_row }>
            <div>
              <h3>{ leadLine }</h3>
              <h1>{ heroLine }</h1>
              <p>All Our Lawyers Provide:</p>
              <ul>
                {
                  heroPoints.map((point, index) => {
                    return (
                      <li key={ index }>
                        { point }
                      </li>
                    )
                  })
                }
              </ul>
            </div>
            <div>
              <Form />
            </div>
          </div>
        </div>
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
        }}>
          <div className={ styles.hero__filter }></div>
          <Img
            style={{
              height: '100%'
            }}
            className={ styles.hero__mobile_resize }
            fluid={ data.hero.childImageSharp.fluid }
          />
        </div>
      </div>
      <div className={ styles.hero__fixed_top_left }>
        <Link
          to="/"
          title="Return home"
        >
          <Img
            fixed={ data.logo.childImageSharp.fixed }
            alt="Motor Injury Help Line"
          />
        </Link>
      </div>
    </div>
  )
}

const LandingHero = ({ leadLine, heroLine, heroPoints }) => (
  <StaticQuery
    query={graphql`
      query LandingHero {
        logo: file(relativePath: { eq: "top-left-hero.png" }) {
          childImageSharp {
            fixed(width: 530, height: 230) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        hero: file(relativePath: { eq: "Motorway-Alt.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data =>
      <Hero
        leadLine={leadLine}
        heroLine={heroLine}
        heroPoints={heroPoints}
        data={data}
      />
    }
  />
)

LandingHero.propTypes = {
  leadLine: PropTypes.string,
  heroLine: PropTypes.string.isRequired,
  heroPoints: PropTypes.array,
}

export default LandingHero
