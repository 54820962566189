import React from 'react'
import PropTypes from 'prop-types'

import Navigation from '../../navigation'
import Footer from '../../footer'

import styles from './layout.modules.scss'

const LandingPageLayout = ({ children }) => (
  <div className={ styles.body }>
    <Navigation alternateText={true} />
    <div>
      { children }
    </div>
    <Footer hideContactForm={true} />
  </div>
)

LandingPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LandingPageLayout
