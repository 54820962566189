import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import LandingPageLayout from '../../components/layout/landing'
import SEO from '../../components/seo'
import HomeHero from '../../components/hero/landing'
import InfoCard from '../../components/infocard'
import HorizontalForm from '../../components/forms/horizontal'
import Contact from '../../components/contact'

import styles from '../../styles/homepage.module.scss'

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query LandingPageQuery {
        homepage {
          title
          description
          heroLeadLine
          heroLine
          heroPoints
          infoTitle
          infoCards {
            cardImage
            cardTitle
            cardDescription
          }
          callToActionTitle
          contactTitle
          contactNumber
          emailAddress
          blogTitle
        }

        allFile(filter: { extension: { eq: "svg" } }) {
          edges {
            node {
              name
              url: publicURL
            }
          }
        }
      }
    `}
    render={data => (
      <LandingPageLayout>
        <SEO
          title={ data.homepage.title }
          description={ data.homepage.description }
          canonical="https://motorinjuryhelpline.com.au"
        />

        <HomeHero
          leadLine={ data.homepage.heroLeadLine }
          heroLine={ data.homepage.heroLine }
          heroPoints={ data.homepage.heroPoints }
        />

        <div className={ styles.infoCards }>
          <h2>{ data.homepage.infoTitle }</h2>
          <div className={ styles.infoCards__flex }>
            { data.homepage.infoCards.map((info, index) => {
              let image

              data.allFile.edges.forEach(edge => {
                if (edge.node.name === info.cardImage) {
                  image = edge.node.url
                }
              })

              return <InfoCard
                image={ image }
                title={ info.cardTitle }
                description={ info.cardDescription }
                homepage
                key={ index }
              />
            })}
          </div>

          <HorizontalForm
            formTitle={ data.homepage.callToActionTitle }
          />

          <div className={ styles.infoCards__dark } />
        </div>

        <Contact />
      </LandingPageLayout>
    )}
  />
)

export default IndexPage
